import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SnackService } from "../../shared/services/extra/snack.service";
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { AlertService } from '../../shared/services/alert.service';
import { MatSort } from "@angular/material/sort";
import { Alert } from '../../shared/interfaces/alert';
import { MatDialog } from '@angular/material/dialog';
import { AlertCommentFormComponent } from './comment/alert-comment-form.component';


@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ["./alerts.component.scss"]
})
export class AlertsComponent implements OnInit, AfterViewInit, OnDestroy {
  title = "";
  toolTip = "";
  loading = true;
  displayedColumns = [];
  data = [];
  showInactive = false;
  dataSource: MatTableDataSource<Alert>;
  selection = new SelectionModel<Alert>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _snack: SnackService,
    private alertService: AlertService,
    public _dialog: MatDialog
  ) {
    this.title = "Alerts";
    this.toolTip = "View active alerts and delete alerts that are no longer active";
    this.displayedColumns = ["select", "basicName", "customerName", "assetName", "scoutName", "basicDescription",
      "triggeredStartDate", "triggeredCount"];
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Alert>();

    this.initializeDataSource();
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngOnDestroy(): void {
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  refresh() {
    this.initializeDataSource();
  }

  initializeDataSource() {
    this.loading = true;

    this.alertService.getAlerts(this.showInactive).subscribe((results) => {
      this.data = results;

      let index = (this.data[0].triggeredStartDate as string).indexOf("T");

      for (let i = 0; i < this.data.length; i++) {
        this.data[i].triggeredStartDate = (this.data[i].triggeredStartDate as string).slice(0, index);
      }
      this.dataSource.data = this.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
      this._snack.displaySuccess("Alerts loaded");
    });
  }

  loadAlerts() {
    this.alertService.getAlerts(this.showInactive).subscribe((results) => {
      this.data = results;

      let index = (this.data[0].triggeredStartDate as string).indexOf("T");

      for (let i = 0; i < this.data.length; i++) {
        this.data[i].triggeredStartDate = (this.data[i].triggeredStartDate as string).slice(0, index);
      }
      this.dataSource.data = this.data;
    });
  }

  removeSelectedData() {
    let selectionIds = this.selection.selected.map(s => s.id) as string[];

    const dialogRef = this._dialog.open(AlertCommentFormComponent, {
      width: "80vw",
      data: this.selection.selected,
    });

    dialogRef.afterClosed().subscribe(results => {
      if (results) {
        this.alertService.clearAlerts(selectionIds).subscribe((results) => {
          this.loadAlerts();
          this.selection.clear();
        });
      }
      else {
        console.log("Canceled comments");
      }
    });
  }

  toggleShowInactive() {
    this.showInactive = !this.showInactive;

    if (this.showInactive) {
      this.displayedColumns = this.displayedColumns.concat("alertActive")
      this.displayedColumns = this.displayedColumns.concat("comment");
    }
    else {
      this.displayedColumns = this.displayedColumns.slice(0, -2);
    }

    this.loadAlerts();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.clear();
    this.selection.select(...this.dataSource.filteredData);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
