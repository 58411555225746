import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { UtilityService } from "../../shared/services/utility.service";
import { SnackService } from "../../shared/services/extra/snack.service";
import { UtilityRun } from "../../shared/classes/utility-run";
import { UtilityRunParameter } from "../../shared/classes/utility-run-parameter";
import { UtilityRunRequest } from "../../shared/classes/utility-run-request";
import { Utility } from "../../shared/classes/utility";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import * as moment from "moment";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit, AfterViewInit {
  loading = true;
  utilities: Utility[];
  selectedUtility: Utility;
  utilityRun: UtilityRun;
  reportGenerated = false;
  displayedColumns: string[][] = [];
  dataSources: MatTableDataSource<any>[] = [];
  fileNames: string[] = [];
  hideTable = true;
  parameterFormGroup: FormGroup;
  utilityFilterCtrl: FormControl = new FormControl('');
  filteredUtilities: Utility[] = [];
  private paginator: MatPaginator;
  paginatorIndex = 0;

  @ViewChild(MatPaginator, {static: false}) set matPaginator(mp: MatPaginator) {
    if (this.dataSources != null && this.dataSources.length > 0) {
      this.paginator = mp;
      this.dataSources[0].paginator = this.paginator;
    }
  }

  protected _onDestroy = new Subject<void>();

  constructor(
    private _utilityService: UtilityService,
    private _snack: SnackService,
  ) {
  }

  ngOnInit() {
    this.loadUtilities();
    this.loading = false;
  }

  ngAfterViewInit() {
    this.loading = false;
  }

  chooseUtility(event: any): void {
    this.filteredUtilities = Object.assign([], this.utilities);

    this.utilityRun = null;
    this.parameterFormGroup = null;
    this.reportGenerated = false;

    this.selectedUtility = this.utilities.find(u => u.id == event.value);

    this.selectedUtility.publicInfo.request.parameters.sort((a, b) => a.order - b.order);

    this.createUtilityRun();
  }

  createUtilityRun(): void {
    if (this.selectedUtility == null) {
      return;
    }

    let parameters = [];
    let controls = {};

    for (let i = 0; i < this.selectedUtility.publicInfo.request.parameters.length; i++) {
      let param = this.selectedUtility.publicInfo.request.parameters.find(p => p.order == i);

      let validators: ValidatorFn[] = [];

      switch (param.typeName) {
        case "string":
          parameters.push(new UtilityRunParameter<string>(param.code, param.valueDefaultText));

          if (param.length > 0) {
            validators.push(Validators.maxLength(param.length));
          }

          if (!param.isOptional) {
            validators.push(Validators.required);
          }

          controls[param.code] = new FormControl(param.valueDefaultText, validators);
          break;
        case "int":
          parameters.push(new UtilityRunParameter<number>(param.code, Number.parseFloat(param.valueDefaultText)));

          validators = [Validators.max(Number.parseFloat(param.valueMaxText)), Validators.min(Number.parseFloat(param.valueMinText))];

          if (!param.isOptional) {
            validators.push(Validators.required);
          }

          controls[param.code] = new FormControl(Number.parseFloat(param.valueDefaultText), validators);
          break;
        case "datetimeoffset":
          parameters.push(new UtilityRunParameter<Date>(param.code, null));

          if (!param.isOptional) {
            validators.push(Validators.required);
          }

          controls[param.code] = new FormControl("", validators);
          break;
        case "bool":
          parameters.push(new UtilityRunParameter<number>(param.code, Number.parseInt(param.valueDefaultText)));

          validators = [Validators.max(Number.parseFloat(param.valueMaxText)), Validators.min(Number.parseFloat(param.valueMinText))];

          if (!param.isOptional) {
            validators.push(Validators.required);
          }

          controls[param.code] = new FormControl(Number.parseInt(param.valueDefaultText), validators);
          break;
        default:
          parameters.push(new UtilityRunParameter(param.code, ""));
          break;
      }
    }

    this.parameterFormGroup = new FormGroup(controls);

    this.utilityRun = new UtilityRun(this.selectedUtility.id, this.selectedUtility.ownerId,
      this.selectedUtility.whenBegin, this.selectedUtility.whenEnd, this.selectedUtility.timeout,
      this.selectedUtility.importance, this.selectedUtility.description, new UtilityRunRequest(parameters));
  }

  generateReport(): void {
    this.reportGenerated = false;

    if (this.selectedUtility == null || this.utilityRun == null) {
      console.error("The a utility hasn't been selected or the utilityRun hasn't been generated!");
      return;
    }

    //update parameter values from form
    for (let i = 0; i < this.utilityRun.request.parameters.length; i++) {
      if (this.selectedUtility.publicInfo.request.parameters[i].typeName == "datetimeoffset") {
        // if it is a date object, convert it to a date string
        this.utilityRun.request.parameters[i].value =
          (this.parameterFormGroup.controls[this.utilityRun.request.parameters[i].code].value as Date).toJSON();
      }
      else if (this.selectedUtility.publicInfo.request.parameters[i].typeName == "string" &&
        this.parameterFormGroup.controls[this.utilityRun.request.parameters[i].code].value == "") {
        this.utilityRun.request.parameters[i].value = null;
      }
      else {
        this.utilityRun.request.parameters[i].value =
          this.parameterFormGroup.controls[this.utilityRun.request.parameters[i].code].value;
      }
    }

    this._snack.displayLoading("Loading report");

    this._utilityService.runUtilityRun(this.utilityRun).subscribe((ret) => {
      if (ret == null) {
        return;
      }

      this._snack.displaySuccess("Report loaded");

      this.utilityRun = ret;
      this.dataSources = [];
      this.displayedColumns = [];
      this.fileNames = [];

      // go through datasets in attributes in selectedUtility in order to display the results
      for (let i = 0; i < this.selectedUtility.publicInfo.result.datasets.length; i++) {
        let utilityDataset = this.selectedUtility.publicInfo.result.datasets[i];
        let tempColumns = [];
        for (let j = 0; j < utilityDataset.attributes.length; j++) {
          let attribute = utilityDataset.attributes.find(a => a.order == j);
          tempColumns.push(attribute.code);
        }

        this.displayedColumns.push(tempColumns);

        let utilityRunDataset = this.utilityRun.response.datasets[i];

        let data = [];

        if (utilityRunDataset != null) {
          for (let j = 0; j < utilityRunDataset.rows.length; j++) {
            let value = {};
            for (let k = 0; k < utilityRunDataset.rows[j].values.length; k++) {
              value[this.displayedColumns[i][k]] = utilityRunDataset.rows[j].values[k];
            }
            data.push(value);
          }
        }

        this.dataSources.push(new MatTableDataSource(data));

        let date = moment(new Date()).format("YYYY-MM-DD");

        this.fileNames.push(date + "." + this.selectedUtility.publicInfo.result.datasets[i].code);
      }

      this.reportGenerated = true;
    });
  }

  tabChange(event: any) {
    this.dataSources[event.index].paginator = this.paginator;
    this.dataSources[this.paginatorIndex].paginator = null;
    this.paginatorIndex = event.index;
  }

  stringErrorMessage(code: string, index: number): string {
    let formControl = this.parameterFormGroup.controls[code];

    if (formControl.hasError('required')) {
      return "Input required";
    }
    else if (formControl.hasError('maxlength')) {
      return "Input has a max length of " + formControl.getError('maxlength').requiredLength;
    }
    else if (formControl.hasError('format')) {
      return "Input must match the format " + this.selectedUtility.publicInfo.request.parameters[index].format;
    }

    return "";
  }

  numberErrorMessage(code: string, index: number): string {
    let formControl = this.parameterFormGroup.controls[code];

    if (formControl.hasError('nan')) {
      return "Value must be a number";
    }
    else if (formControl.hasError('required')) {
      return "Input required";
    }
    else if (formControl.hasError('max') || formControl.hasError('min')) {
      let param = this.selectedUtility.publicInfo.request.parameters[index];
      return "Value must be between " + param.valueMinText + " and " + param.valueMaxText;
    }

    return "";
  }

  dateTimeErrorMessage(code: string, index: number): string {
    let formControl = this.parameterFormGroup.controls[code];

    if (formControl.hasError('datefuture')) {
      return "Date cannot be past the current date";
    }

    return "";
  }

  stringInputChange(input: string, code: string): void {
    let param = this.selectedUtility.publicInfo.request.parameters.find(p => p.code == code);

    // if there's no format provided, don't look for error
    if (param == null || param.format == "" || param.format == null) {
      return;
    }

    let regex = new RegExp("^\\d{3}\\.\\d{2}$");

    if (!regex.test(input)) {
      let errors = this.parameterFormGroup.controls[code].errors;

      if (errors == null) {
        errors = {};
      }
      errors['format'] = true;

      this.parameterFormGroup.controls[code].setErrors(errors);
    }
  }

  numberInputChange(input: any, code: string): void {
    if (input.validity.badInput) {
      let errors = this.parameterFormGroup.controls[code].errors;

      if (errors == null) {
        errors = {};
      }
      errors['nan'] = true;

      this.parameterFormGroup.controls[code].setErrors(errors);
    }
  }

  dateInputChange(input: string, code: string): void {
    let date = new Date(input);

    if (date > new Date()) {
      let errors = this.parameterFormGroup.controls[code].errors;

      if (errors == null) {
        errors = {};
      }
      errors['datefuture'] = true;

      console.log(date);

      this.parameterFormGroup.controls[code].setErrors(errors);
    }
  }

  filterUtilities(): void {
    if (!this.utilities) {
      return;
    }
    // get the search keyword
    let search = this.utilityFilterCtrl.value;
    if (!search) {
      this.filteredUtilities = Object.assign([], this.utilities);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the utilities
    this.filteredUtilities = this.utilities.filter(util => util.name.toLowerCase().indexOf(search) > -1);
  }

  loadUtilities(): void {
    this._utilityService.getUtilites().subscribe((utilities) => {
      utilities.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      this.utilities = utilities;

      this.filteredUtilities = Object.assign([], this.utilities);

      this.utilityFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterUtilities();
        });
    });
  }
}