import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantService } from './constant.service';
import { SnackService } from './extra/snack.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Alert } from '../interfaces/alert';

@Injectable()
export class AlertService {
  params: any;
  private authHeaders: HttpHeaders;
  constructor(
    private _http: HttpClient,
    private _constService: ConstantService,
    private _snackService: SnackService
  ) { }

  public getAlerts(showInactive: boolean): Observable<Alert[]> {
    const params: any = {
      "showInactive": showInactive,
      "code": "/NWhwJUEJRFxxqv5eO8TTuaxENlPk0lIYomWfO6ZrL5ehPGL0Dieiw=="
    };

    const URL = `${this._constService.CONST.reportsApiUrl}api/GetAlerts`;
    return this._http.get<Alert[]>(URL, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public clearAlerts(ids: string[]): Observable<Alert[]> {
    const params: any = {
      "code": "/NWhwJUEJRFxxqv5eO8TTuaxENlPk0lIYomWfO6ZrL5ehPGL0Dieiw=="
    };

    const URL = `${this._constService.CONST.reportsApiUrl}api/ClearAlerts`;
    return this._http.post<any[]>(URL, ids.toString(), { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

  public setAlertComments(alertsAndComments: any[]): Observable<boolean> {
    const params: any = {
      "code": "/NWhwJUEJRFxxqv5eO8TTuaxENlPk0lIYomWfO6ZrL5ehPGL0Dieiw=="
    };

    const URL = `${this._constService.CONST.reportsApiUrl}api/SetAlertComment`;
    return this._http.post<any[]>(URL, alertsAndComments, { params: params }).pipe(
      map((res: any) => res),
      catchError(this._constService.handleErrorObservable)
    );
  }

}
