<div>
    <mat-form-field class="filter">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Battery..." #input>
    </mat-form-field>
    <mat-table [dataSource]="dataSource" matSort class="comments-table">
        <ng-container matColumnDef="basicName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Alert Type
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.basicName}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customerName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Customer Name
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.customerName}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assetName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Asset Name
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.assetName}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="scoutName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Scout Name
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.scoutName}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="basicDescription">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Additional Info
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.basicDescription}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="triggeredStartDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Start Date
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.triggeredStartDate}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="triggeredCount">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Days Active
            </mat-header-cell>
            <mat-cell *matCellDef="let alert">
                {{alert.triggeredCount}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Write a Comment
            </mat-header-cell>
            <mat-cell *matCellDef="let alert; let i = index;">
                <mat-form-field class="comment-form">
                    <textarea matInput type="text" [(ngModel)]="alert.comment"></textarea>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td *ngIf="input.value.length > 0" class="mat-cell">
                No data matching the filter "{{input.value}}"
            </td>
            <td *ngIf="input.value.length == 0" class="mat-cell">
                No data
            </td>
        </tr>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" aria-label="Select page of alerts"></mat-paginator>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="warn" (click)="cancelComments()">
        Cancel
    </button>
    <button mat-flat-button (click)="submitComments()" class="submit-button">
        Clear Alerts with Comments
    </button>
</div>