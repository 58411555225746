import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Alert } from '../../../shared/interfaces/alert';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-alert-comment-form',
  templateUrl: './alert-comment-form.component.html',
  styleUrls: ['./alert-comment-form.component.css']
})
export class AlertCommentFormComponent implements OnInit, AfterViewInit {
  dataSource: MatTableDataSource<Alert>;
  displayedColumns = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<AlertCommentFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
  ) {
    this.displayedColumns = ["basicName", "customerName", "assetName", "scoutName", "basicDescription",
      "triggeredStartDate", "triggeredCount", "comment"];
  }
  public event: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Alert>();
    this.dataSource.data = this.data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  submitComments() {
    let ret = [];

    for (let i = 0; i < this.dataSource.data.length; i++) {
      ret.push({
        "alertId": this.dataSource.data[i].id,
        "comment": this.dataSource.data[i].comment
      });
    }

    this.alertService.setAlertComments(ret).subscribe(results => {
      console.log(results);
      this.dialogRef.close(true);
    });

    this.dialogRef.close(true);
  }

  cancelComments() {
    this.dialogRef.close(false);
  }

}
