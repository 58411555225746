import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component';
import { AlertsRoutingModule } from './alerts-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { MatModule } from '../../core';
import { AlertCommentFormComponent } from './comment/alert-comment-form.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    AlertsRoutingModule,
    SharedModule,
    MatModule,
    FormsModule,
  ],
  declarations: [
    AlertsComponent,
    AlertCommentFormComponent
  ]
})
export class AlertsModule { }